<template>
    <div id="slot">
        <div class="wrapper">
            <template v-if="gameUrl">
                <iframe :src="gameUrl" scrolling="no" frameborder="0" allow=“autoplay;clipboard-write;fullscreen”></iframe>
            </template>
        </div>
    </div>
</template>
<script>
    // import Auth from "@/services/auth.js"
    export default {
        data() {
            return {
                url: 'https://game.aviatrix.bet/',
                key: 'aee2e5f0-a356-45c9-abe9-c28b502fee98',
                cid:'rahisibet',
                lobbyUrl: 'https://rahisibet.com',
                isDemo:true,
                isFull:true,
                gameUrl:null
            }
        },
        mounted() {
            let str =`${this.url}?cid=${this.cid}&productId=nft-aviatrix&lobbyUrl=${this.lobbyUrl}&isDemo=${this.isDemo}&lang=${this.$i18n.locale}`;
            this.gameUrl = str;
        },
    }
</script>
<style scoped>
    .main-body {
        padding-top: 120px !important;
    }
    iframe {
        min-width: 95vw;
        min-height: 85vh;
        width: 100%;
        height: 100%;
    }
    .reset {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    .full-screen {
        display: flex;
        justify-content: space-between;
        padding: 10px 5px
    }
    .full-screen span {
        cursor: pointer;
    }
</style>